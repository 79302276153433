import { Button, Icon } from '@chakra-ui/react'
import { ArrowLeft, ArrowRight } from 'lucide-react'

export default function Arrow(props: {
  disabled: boolean
  left?: boolean
  hideOnDisabled?: boolean
  centered?: boolean
  onClick: (e: any) => void
}) {
  if (props.centered) {
    return (
      <Button
        variant={'pill'}
        size="sm"
        position="absolute"
        top="50%"
        transform={'translateY(-50%)'}
        left={props.left ? '5px' : 'auto'}
        right={!props.left ? '5px' : 'auto'}
        padding={0}
        opacity={0.8}
      >
        <Icon
          as={props.left ? ArrowLeft : ArrowRight}
          onClick={props.onClick}
          opacity={props.disabled ? 0.2 : 1}
          width={5}
          height={5}
          color="black"
        ></Icon>
      </Button>
    )
  }
  return (
    <Button variant={'link'}>
      <Icon
        as={props.left ? ArrowLeft : ArrowRight}
        onClick={props.onClick}
        opacity={props.disabled ? 0.2 : 1}
        width={6}
        height={6}
        color="black"
      ></Icon>
    </Button>
  )
}
