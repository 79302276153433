import { chakra } from '@chakra-ui/react'
import NextImage from 'next/image'

const ChakraNextImage = chakra(NextImage, {
  shouldForwardProp: (prop) =>
    [
      'width',
      'height',
      'src',
      'alt',
      'quality',
      'placeholder',
      'blurDataURL',
      'loader',
      'fill',
      'style',
      'sizes',
    ].includes(prop),
})

export default ChakraNextImage
