import { Box, Flex, Link, LinkBox, LinkOverlay, Text } from '@chakra-ui/react'
import RouterLink from 'next/link'
import { Campus as CampusType, MainImage, Promo, Service as ServiceType } from '../types'
import ChakraNextImage from './ChakraNextImage'

const EntryPromo = ({ entry, width, version, type }: Props) => {
  if (width === 'full') {
    return (
      <LinkBox
        as={Flex}
        minHeight={['50vw', '30vw']}
        flexDirection={['column', 'row']}
        backgroundColor={'green.50'}
        borderRadius={'xl'}
        _hover={{
          backgroundColor: 'green.100',
          img: {
            opacity: 0.9,
          },
        }}
        sx={{
          transition: 'all 0.2s ease-in-out',
        }}
      >
        <Box
          position="relative"
          width={['full', '50%']}
          minHeight={['50vw', '30vw']}
          flex="none"
          backgroundColor="green.100"
          borderTopLeftRadius={'xl'}
          borderTopRightRadius={['xl', 'none']}
          borderBottomLeftRadius={['none', 'xl']}
        >
          <ChakraNextImage
            borderTopLeftRadius={'xl'}
            borderTopRightRadius={['xl', 'none']}
            borderBottomLeftRadius={['none', 'xl']}
            objectFit="cover"
            objectPosition={
              `${entry?.mainImage?.[0]?.focalPoint?.[0] * 100}% ${
                entry?.mainImage?.[0]?.focalPoint?.[1] * 100
              }%` || 'center'
            }
            transition="all 0.2s ease-in-out"
            // @ts-ignore
            fill
            alt={entry?.mainImage?.[0]?.title}
            src={entry?.mainImage?.[0]?.path}
            placeholder="blur"
            blurDataURL={entry?.mainImage?.[0]?.imageOptimize?.placeholderImage}
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
          />
        </Box>
        <Flex flexDirection={'column'} justifyContent="center" p={[5, 12]} gap={2} minHeight={120}>
          <RouterLink
            href={entry?.entryLink?.[0]?.url || entry?.externalLink || entry?.uri || ''}
            passHref
            data-keen-slider-clickable
            legacyBehavior
          >
            <LinkOverlay
              fontWeight={'medium'}
              fontSize={['md', 'xl']}
              isExternal={Boolean(entry.externalLink)}
            >
              {entry?.title}
            </LinkOverlay>
          </RouterLink>
          <Text noOfLines={3} fontSize={['sm', 'md']} whiteSpace="normal">
            {entry?.description}
          </Text>
        </Flex>
      </LinkBox>
    )
  }

  if (width === '50%') {
    return (
      <LinkBox
        as={Flex}
        width={['full', width]}
        flexDirection={['column']}
        backgroundColor={'green.50'}
        borderRadius={'xl'}
        _hover={{
          backgroundColor: 'green.100',
          img: {
            opacity: 0.9,
          },
        }}
        sx={{
          transition: 'all 0.2s ease-in-out',
        }}
      >
        <Box
          position="relative"
          height={['50vw', '20vw']}
          width={['full']}
          flex="none"
          backgroundColor="green.100"
          borderTopLeftRadius={'xl'}
          borderTopRightRadius={'xl'}
        >
          <ChakraNextImage
            borderTopLeftRadius={'xl'}
            borderTopRightRadius={['xl']}
            objectFit="cover"
            objectPosition={
              `${entry?.mainImage?.[0]?.focalPoint?.[0] * 100}% ${
                entry?.mainImage?.[0]?.focalPoint?.[1] * 100
              }%` || 'center'
            }
            transition="all 0.2s ease-in-out"
            // @ts-ignore
            fill
            alt={entry?.mainImage?.[0]?.title}
            src={entry?.mainImage?.[0]?.path}
            placeholder="blur"
            blurDataURL={entry?.mainImage?.[0]?.imageOptimize?.placeholderImage}
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
          />
        </Box>
        <Flex flexDirection={'column'} justifyContent="center" p={[5, 6]} gap={2} minHeight={120}>
          <RouterLink
            href={entry?.entryLink?.[0]?.url || entry?.externalLink || entry?.uri || ''}
            passHref
            data-keen-slider-clickable
            legacyBehavior
          >
            <LinkOverlay
              fontWeight={'medium'}
              fontSize={['md', 'xl']}
              isExternal={Boolean(entry.externalLink)}
            >
              {entry?.title}
            </LinkOverlay>
          </RouterLink>
          <Text noOfLines={3} fontSize={['sm', 'md']} whiteSpace="normal">
            {entry?.description}
          </Text>
        </Flex>
      </LinkBox>
    )
  }

  if (version === 2) {
    return (
      <Box
        className="keen-slider__slide" // mr={6}
        minWidth={['75vw', null, 'calc(33% - 12px)']}
        maxWidth={[null, null, 'calc(33% - 12px)']}
        whiteSpace="normal"
        bg="green.50"
        borderTopRadius="12px"
        borderBottomRadius="12px"
        // sx={{
        //   scrollSnapAlign: 'start',
        // }}

        height={['320px', '400px', '400px']}
      >
        <Box
          bg="green.100"
          height={['40vw', null, '200px']}
          position="relative"
          borderTopRadius="12px"
          sx={{
            span: {
              borderTopRadius: '12px',
            },
            img: {
              borderTopRadius: '12px',
            },
          }}
        >
          <RouterLink
            href={entry.entryLink?.[0]?.url || entry.externalLink || entry?.uri || ''}
            data-keen-slider-clickable
          >
            <ChakraNextImage
              objectFit="cover"
              objectPosition={`${entry.mainImage?.[0]?.focalPoint?.[0] * 100}% ${
                entry.mainImage?.[0]?.focalPoint?.[1] * 100
              }%`}
              // @ts-ignore
              fill
              alt={entry.mainImage?.[0]?.title}
              src={entry.mainImage?.[0]?.path}
              placeholder="blur"
              blurDataURL={entry.mainImage?.[0]?.imageOptimize?.placeholderImage}
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            />
          </RouterLink>
        </Box>
        <Box minHeight={['35vw', null, '200px']} p={5}>
          <RouterLink
            href={entry.entryLink?.[0]?.url || entry.externalLink || entry?.uri || ''}
            passHref
            data-keen-slider-clickable
            legacyBehavior
          >
            <Link isExternal={Boolean(entry.externalLink)}>
              <Text fontFamily="heading" fontWeight="medium-display" fontSize={['md', 'lg']}>
                {entry.title}
              </Text>
            </Link>
          </RouterLink>
          <Text fontSize={['sm', 'md']} mt={2} noOfLines={3} whiteSpace="normal">
            {entry.description}
          </Text>
        </Box>
      </Box>
    )
  }
}

type Props = {
  width?: 'full' | '50%' | '33%'
  entry?: Promo
  version?: number
  type?: 'promotion' | 'offer'
}

export default EntryPromo
