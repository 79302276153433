import { Box, Flex, FlexProps } from '@chakra-ui/react'
import 'keen-slider/keen-slider.min.css'
import { useKeenSlider } from 'keen-slider/react'
import { FunctionComponent, useEffect, useState } from 'react'
import ImageArrow from './ImageArrow'

type ScrollerProps = {
  children: JSX.Element[]
  type?: 'promo' | 'offer' | 'image'
  dots?: boolean
} & FlexProps

const Scroller: FunctionComponent<ScrollerProps> = ({
  children,
  type = 'image',
  dots = false,
  ...rest
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [dragging, setDragging] = useState(false)

  const [sliderRef, instanceRef] = useKeenSlider({
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel)
    },
    created() {
      setLoaded(true)
    },
    dragStarted: () => {
      setDragging(true)
    },
    dragEnded: () => {
      setDragging(false)
    },
    breakpoints: {
      '(min-width: 48em)': {
        mode: 'snap',
        slides: { perView: 'auto', spacing: 24 },
      },
    },
    slides: {
      perView: 'auto',
      spacing: 24,
      // origin: 'center',
    },
    mode: 'free-snap',
  })

  useEffect(() => {
    instanceRef.current?.update()
  }, [children, instanceRef])

  return (
    <Box position="relative">
      <Flex
        ref={sliderRef}
        className="keen-slider"
        pt={4}
        pb={3}
        sx={{
          overflow: 'visible !important',
          '.keen-slider__slide': {
            cursor: dragging ? 'grabbing' : 'grab',
          },
        }}
        {...rest}
      >
        {children}
      </Flex>
      {!dots &&
        loaded &&
        instanceRef.current &&
        instanceRef.current?.track?.details?.slides?.length > 1 && (
          <Flex
            justifyContent={'end'}
            mt={2}
            visibility={[
              'visible',
              instanceRef.current?.track?.details?.slides?.length <= 3 ? 'hidden' : 'visible',
            ]}
          >
            <ImageArrow
              hideOnDisabled
              left
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.prev()}
              disabled={currentSlide === 0}
            />

            <ImageArrow
              hideOnDisabled
              onClick={(e: any) => e.stopPropagation() || instanceRef.current?.next()}
              disabled={
                currentSlide === instanceRef.current?.track?.details?.slides?.length - 1 ||
                // @ts-ignore
                instanceRef.current?.options?.slides?.perView ===
                  instanceRef.current?.track?.details?.slides?.length
              }
            />
          </Flex>
        )}
      {dots && loaded && instanceRef.current && (
        <div className="dots">
          {[
            ...Array.from({ length: instanceRef.current?.track?.details?.slides?.length }).keys(),
          ].map((idx) => {
            return (
              <button
                key={idx}
                onClick={() => {
                  instanceRef.current?.moveToIdx(idx)
                }}
                className={'dot' + (currentSlide === idx ? ' active' : '')}
              ></button>
            )
          })}
        </div>
      )}
    </Box>
  )
}

export default Scroller
