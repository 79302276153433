import { Box } from '@chakra-ui/react'
import Image from 'next/image'
import { Campus as CampusType, MainImage, Offer, Service as ServiceType } from '../types'

const EntryOffer = ({ offer, fullWidth = false, version, type }: Props) => {
  if (version === 2) {
    return (
      <Box
        className="keen-slider__slide"
        minWidth={['75vw', null, '320px']}
        maxWidth={[null, null, '320px']}
        height="full"
        whiteSpace="normal"
        bg="green.50"
        borderRadius="22px"
      >
        <Box
          bg="green.100"
          height={['75vw', null, '320px']}
          position="relative"
          borderRadius="22px"
          sx={{
            span: {
              borderRadius: '22px',
            },
            img: {
              borderRadius: '22px',
            },
          }}
        >
          <Image
            fill
            style={{ objectFit: 'cover' }}
            alt={offer.mainImage?.[0]?.title}
            src={offer.mainImage?.[0]?.path}
            placeholder="blur"
            blurDataURL={offer.mainImage?.[0]?.imageOptimize?.placeholderImage}
            sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
          />
        </Box>
      </Box>
    )
  }
}

type Props = {
  fullWidth?: boolean
  offer?: Offer
  version?: number
  type?: 'promotion' | 'offer'
}

export default EntryOffer
